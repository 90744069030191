import { Component, OnInit, ViewChild } from "@angular/core";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import { Tools } from "../../modules/tools";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { BookingStatusModel } from "../../models/booking.status.model";
import { BookingModel } from "../../models/booking.model";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "src/app/util/components/modalcomponent/modal-content";
import {ActivebookingService } from "src/app/services/activebooking.service";
import { AuthService } from "src/app/services/auth.service";
import { ChargerService } from "src/app/services/charger.service";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { Router,ActivatedRoute } from "@angular/router";
export class searchField {
  searchField?: number;
  chargerId?: string;
  bookingId?: number;
  startdate?: Date;
  enddate?: Date;
  status?:string;
  username?:string;
}
export class EveryBookingModel {
  bookingId?: number;
  chargerName?: string;
  chargingpoint?: string;
  userEVId?: number;
  userEV?: number;
  username?: string;
  ScheduleId?: number;
  start_time?: string;
  stop_time?: string;
  discountcode?: string;
  book_time?:string;
  location?:string;
  amount?:number;
  requested_stop_duration?:number;
  requested_stop_time?:string;
  requested_stop_unit?:number;
  planId?:number;
  scheduledID?:number;
  bookedvia?:string;
  vehicleName?:string;
  vehicleChasis?:string;
  stopchargingby?:string;
  /*
   * S- Booking accepted
   * R -  Charging Running
   * C - Charging Complete
   */
  priceby?:string;
  pricing?: number;
  damount?:string;
  tdamount?:number;
  extracharges?:number;
  taxes?:number;
  status?: string;
  tran_id?: number;
  userstopattempts?:string;
  userstartattempts?:string;
}
@Component({
  selector: 'app-activebooking',
  templateUrl: './activebooking.component.html',
  styleUrls: ['./activebooking.component.css']
})
export class ActivebookingComponent implements OnInit{
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  bookings: Array<BookingModel>;
  datalist: Array<BookingStatusModel>;
  chargerslist: Array<ManageChargerModel>;
  data: EveryBookingModel;
  _data: BookingModel;
  _menuId: string;
  page: GridPage;
  _action: string;
  cancelText:string;
  isInitiate:boolean = false;
  isDetailScreen: boolean = false;
  isBooked: boolean = false;
  isInvoice: boolean = false;
  pricedBy : string ="";
  isRquestedStopDuration: boolean = false;
  isRquestedStopUnit: boolean = false;
  dateNow: Date = new Date();
  topbartext: TopbarText[] = [{ key: "activebooking", displayText: " Active Bookings" }];
  searchField: searchField;
 
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", 
    isSrCol: true, 
    columnClasses: "c-col-pc-5" },
    {
      columnHeader: "Booking Id",
      columnFieldName: "bookingId",
      columnClasses: "c-col-pc-8",
    },
    {
      columnHeader: "Charger",
      columnFieldName: "chargerName",
      columnClasses: "c-col-pc-25"
    },
    {
      columnHeader: "Port",
      columnFieldName: "chargingpoint",
      columnClasses: "c-col-pc-5"
    },
    {
      columnHeader: "Booked Via",
      columnFieldName: "bookedvia",
      columnClasses: "c-col-pc-8 text-center",
      callbackFunction: this.tools.bookedVia
    },
    {
      columnHeader: "User",
      columnFieldName: "username",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Status",
      columnFieldName: "status",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.bookingStatus
    },
    {
      columnHeader: "Booking Time",
      columnFieldName: "start_time",
      columnClasses: "c-col-pc-25",
     callbackFunction: this.tools.defaultdateTimeFormat
    },
    
  ];
  constructor(
    private activebookingService: ActivebookingService,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private activatedRoute:ActivatedRoute,
    public chargerservice: ChargerService,
    private managechargerservice: ManageChargerService, 
    private ngbModalService: NgbModal
  )
   {
    this.searchField = new searchField();
    this.page = new GridPage();
    this.cancelText = "Cancel Booking"
    this.data = new EveryBookingModel();
    this._data = new BookingModel();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/activebooking");
    this.objGlobal.setActiveMenu(this._menuId);
    if(this.activatedRoute.snapshot.paramMap.get('chargerId')!=null){
      this.searchField.chargerId=this.activatedRoute.snapshot.paramMap.get('chargerId');
     }else{
     this.searchField.chargerId="-";
     }
     this.searchField.status="S";
     this.searchField.startdate=this.dateNow;
     this.searchField.enddate=this.dateNow;
     
    this.getBookingData();
    this.getChargers();
    
   }
   
   gridDeleteButton() {
    let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
    return isdelete;
  }
   async getChargers() {
    this.chargerslist = undefined;
    await this.managechargerservice
      .getChargersSelectionListWithSuccessorOperator()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          this.chargerslist = data["result"];
        } else {
          console.error("Error in fetching all chargers");
        }
      });
  }

  getChargersList() {
    return this.chargerslist;
    
  }
  ngOnInit() {
    
    //this.dataSource.data = this.getGroups(this.alldata, this.groupByColumns);
   // this.getChargersLiveStatusData();
  }
  getBookingData() {
    this.activebookingService.getBookingDetails(this.page, this.searchField).subscribe(data => {
    // if(data["result"].content.booking.status!=="P"){
      if (data["success"]) {  
        this.datalist = data["result"].content;
        this.page.first = data["result"].first;
        this.page.last = data["result"].last;
        this.page.number = data["result"].number;
        this.page.numberOfElements = data["result"].numberOfElements;
        this.page.offset = data["result"].offset;
        this.page.size = data["result"].size;
        this.page.totalElements = data["result"].totalElements;
        this.page.totalPages = data["result"].totalPages;
        if (this.page.number == 0) {
          this.gridComp.setPages();
        }
      } else {
        alert("Failed to get Bookings!");
      }
      
//}
     // this.bookings = data["result"].content.booking;
    });
   
    //for(let i=0 ;i<this.datalist.length;i++){
    //  this.bookings=this.datalist.
    //}
  }
     
  cancelBooking(recObj) {
    let objBooking: BookingModel = {};
    if(!this.isDetailScreen){
      objBooking = recObj.obj;
    }
    else{
      objBooking = recObj;
    }
    
    if (objBooking && (objBooking.status=="S"||objBooking.status=="B")) {
      
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to cancel Booking?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.activebookingService
              .cancelBooking(objBooking.bookingId)
              .subscribe(data => {
                if (data["success"]) {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Booking cancelled successfully!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      this.getBookingData();
                    },
                    reason => {
                      this.getBookingData();
                    }
                  );
                } else {
                  alert("Failed to cancel booking!");
               
                }
              });
          }
        },);
    }
    else if (objBooking && objBooking.status=="R") {
      if(!this.isDetailScreen){
        objBooking = recObj.obj;
      }
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Charging Request is accepted, are you sure to cancel Booking?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.activebookingService
              .cancelBooking(objBooking.bookingId)
              .subscribe(data => {
                if (data["success"]) {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Booking cancelled successfully!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      if(this.isDetailScreen){
                        this.getDetail(this._data.bookingId);
                      }else{
                        this.getBookingData();
                      }
                      
                    },
                    reason => {
                      if(this.isDetailScreen){
                        this.getDetail(this._data.bookingId);
                      }else{
                        this.getBookingData();
                      }
                    }
                  );
                }
                else if (data["result"]==="R") {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Booking cannot be cancelled as charging seesion is active on this booking!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      if(this.isDetailScreen){
                        this.getDetail(this._data.bookingId);
                      }else{
                        this.getBookingData();
                      }
                    },
                    reason => {
                      if(this.isDetailScreen){
                        this.getDetail(this._data.bookingId);
                      }else{
                        this.getBookingData();
                      }
                    }
                  );
                } 
                else {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Failed to cancel booking!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      if(this.isDetailScreen){
                        this.getDetail(this._data.bookingId);
                      }else{
                        this.getBookingData();
                      }
                    },
                    reason => {
                      if(this.isDetailScreen){
                        this.getDetail(this._data.bookingId);
                      }else{
                        this.getBookingData();
                      }
                    }
                  );
                
                }
              });
          }
        
        },);
    }
    else {
      if(!this.isDetailScreen){
        objBooking = recObj.obj;
      }
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Information";
      ngmodalref.componentInstance.message = "Booking Cannot be cancelled in this status?";
      
    }
  }
  onBackbuttonClick() {
  this.isDetailScreen=false;
  this.isRquestedStopDuration=false;
  this.isRquestedStopUnit=false;
        this.getBookingData();
  }
  onCancelButtonClick() {
          this.cancelBooking(this._data);
         this.getDetail(this._data.bookingId);
    }
  onRoleRowDblClick(booking: any) {
    this._action = "update";
    this.isDetailScreen = true;
   console.log(this.isDetailScreen);
    //this.router.navigate(['user']);
    this.getDetail(booking.bookingId);
  }

  isStateInitiate(){
    if(this._data.status==="S"||this._data.status==="B"||this._data.status==="R"){
      return true;
    }else{
      return false;
    }

  }setPricedBy(){
    if(this.data.stopchargingby==="U"){
      this.pricedBy = "/Unit"
    }
    else if (this.data.stopchargingby==="M"){
      this.pricedBy = "/Minute"
    }
    else{
      this.pricedBy = "/Unit"
    }
    }
    
    userattempts(val:string){
      if(!val){
      return 0
    }
    else {
      return val
    }
    }
    displayInvoice(){
      if(this.data.status==="P"||this.data.status==="C"){
        this.isInvoice = true;
      }
    }
  statusBooked(){
    if(this.data.status==="B"){
      this.isBooked=true;
    }
  }
  RquestedStopDuration(){
    console.log(this._data.stopchargingby);
    if(this.data.stopchargingby==="M"){
      this.isRquestedStopDuration = true;
    }
  }
  RquestedStopUnit(){
    console.log(this._data.stopchargingby);
    if(this.data.stopchargingby==="U" ||this.data.stopchargingby===undefined||this.data.stopchargingby===null||this.data.stopchargingby==="A"){
      this.isRquestedStopUnit = true;
    }
  }
  statusClassWithId(){
    let statusClass="";
    if(this._data.status==="D"){
      statusClass="bgGrey";
    }else if(this._data.status==="S"){
      statusClass="bgYellow";
    }else if(this._data.status==="R"){
      statusClass="bgYellow";
    }else if(this._data.status==="F"){
      statusClass="bgRed";
    }else if(this._data.status==="P"){
      statusClass="bgGreen";
    }else if(this._data.status==="C"){
      statusClass="bgGreen";
      }
    return statusClass
      }
      formatAmount(val: number) {
        return val.toFixed(2);
      }

  getDetail(bookingId:number) {
    if (bookingId) {
    this.activebookingService.getBooking(bookingId).subscribe(data => {
      if (data["success"]) {
        this._data = data["result"];
        this.data.amount = this._data.amount;
        this.data.book_time = this.tools.defaultdateTimeFormat(this._data.book_time);
        this.data.bookingId = this._data.bookingId;
        this.data.chargerName = this._data.chargerName;
        this.data.chargingpoint = this._data.chargingpoint;
        this.data.discountcode = this._data.discountcode;
        this.data.location = this._data.location;
        this.data.planId = this._data.planId;
        this.data.requested_stop_duration = this._data.requested_stop_duration;
        this.data.requested_stop_time = this.tools.defaultdateTimeFormat(this._data.requested_stop_time);
        this.data.requested_stop_unit = this._data.requested_stop_unit;
        this.data.scheduledID = this._data.scheduledID;
        this.data.start_time = this.tools.defaultdateTimeFormat(this._data.start_time);
        this.data.status = this.tools.bookingStatus(this._data.status);
        this.data.stop_time = this.tools.defaultdateTimeFormat(this._data.stop_time);
        this.data.tran_id = this._data.tran_id;
        this.data.userEVId = this._data.userEVId;
        this.data.username = this._data.username;
        this.data.bookedvia = this.tools.bookedVia(this._data.bookedvia);
        this.data.vehicleChasis = this._data.vehicleChasis;
        this.data.vehicleName = this._data.vehicleName;
        this.data.damount = this.formatAmount(this._data.damount);
        this.data.pricing = this._data.pricing;
        this.data.stopchargingby = this._data.stopchargingby;
        this.data.userstartattempts=this.userattempts(this._data.userstartattempts)+"/3";
        this.data.userstopattempts=this.userattempts(this._data.userstopattempts)+"/3";
        this.RquestedStopDuration();
        this.RquestedStopUnit();
        this.displayInvoice();
        this.statusBooked();
        this.setPricedBy();
        //console.log(this.data.status);
      } else {
        this.data = new EveryBookingModel();
        alert("Failed to get booking data!");
      }
    });
  }
}

}

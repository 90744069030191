import { OperatorModel } from "./operator.model";
import { ManageChargerModel } from "./ManageCharger.model";

export class StationModel {
  stationId?: number;
  stationname?: string;
  contact?: string;
  address?: string;
  city?: string;
  email?: string;
  operator?: OperatorModel;
  longitude?: number;
  lattitude?: number;
  config?:string;
  icon?:string;
  fileData?:string;
  operationalHours?:string;
  amenities?:string;
  stationGroup?:string;
  managechargers?: Array<ManageChargerModel>;
}


import { Component, OnInit, ViewChild, Output, Input,EventEmitter, DoCheck } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import { __values } from 'tslib';
//import { RfidDetailComponent } from "../rfid-detail/community.component";
import { OperatorModel } from "src/app/models/operator.model";
import { HttpResponse, HttpEventType } from '@angular/common/http';
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { OperatorService } from "src/app/services/operator.service";
import { SubscriptionService } from "../../services/subscription.service"
import {GridPage,gridColumns,GridtableComponent} from "../../util/components/gridtable/gridtable.component";
import { SubscriptionModel } from "src/app/models/subscription.model";

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Name",
      columnFieldName: "name",
      columnClasses: "c-col-pc-10"
    },{
      columnHeader: "Scope",
      columnFieldName: "scope",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.scopeValue
    },
    {
      columnHeader: "Status",
      columnFieldName: "status",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.statusValue
      
    },
    {
      columnHeader: "Last Modified",
      columnFieldName: "modts",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Modified By",
      columnFieldName: "",
      columnClasses: "c-col-pc-10",
      
    },
    {
      columnHeader: "Creation Date",
      columnFieldName: "gents",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
  ];
  searchField = {
    searchText: "",
    searchSubscription:"",
    scope:"U",
    status:""
  };
  chargingValue = {
    byCUnit:"",
    byCMinute:"",
    byCHour:"",
  };
  parkingValue = {
    byPUnit:"",
    byPMinute:"",
    byPHour:"",
  };
  cancellationValue = {
    byCAUnit:"",
    byCAMinute:"",
    byCAHour:"",
  };
  otherValue = {
    byOUnit:"",
    byOMinute:"",
    byOHour:"",
  };
  dateNow: Date = new Date();
  data: SubscriptionModel;
    _data: SubscriptionModel;
    chargingFormula:string;
    parkingFormula:string;
    othersFormula:string;
    progress: { percentage: number } = { percentage: 0 };
    _isSaved: boolean = false;
    datalist: Array<SubscriptionModel>;
    operator: OperatorModel;
    page: GridPage;
    _action: string;
    hideP: boolean = false;
    hideO: boolean = false;
    isParking: boolean = true;
    isOthers: boolean = true;
    iscommercialAllowed = false;
    isDetailScreen: boolean = false;
    //_disableSaveCancel: boolean = true;
   // isScreen: boolean = true;
    commercialTypes:string;
    _menuId: string;
    topbartext: TopbarText[] = [{ key: "tariff", displayText: "Tariff Plans" }];
   
  constructor(private ngbModalService: NgbModal,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private subscriptionservice: SubscriptionService) {
      this.data = new SubscriptionModel();
      
      //this.rfidarrlist = new RFIDModel();
      this.page = new GridPage();
      this._menuId = this.tools.getMenuName("main/managetariff");
      this.getSubscriptionList();
     // this.searchField.searchText="";
      this.data.gents=this.dateNow;
      this.data.modts=this.dateNow;
     }

  ngOnInit() {
  }

  showCommercials(){
    if (this.commercialTypes==='P'){
      this.isParking= false;
      this.hideP= true;
      this.commercialTypes="";
    }
    if (this.commercialTypes==='O'){
    this.isOthers = false;
    this.hideO = true;
    }
  }

  removeOthers(){
    this.isOthers = true;
    this.iscommercialAllowed=false;
    this.hideO = false;
    this.otherValue.byOHour="-1"
    this.otherValue.byOMinute="-1"
    this.otherValue.byOUnit="-1"
    
  }

  removeParking(){
    this.isParking = true;
    this.iscommercialAllowed=false;
    this.hideP= false;
    this.parkingValue.byPHour="-1"
    this.parkingValue.byPMinute="-1"
    this.parkingValue.byPUnit="-1"
  }
  

  getSubscriptionList() {
    this.subscriptionservice.getSubscriptionList(this.searchField).subscribe(data => {
        // console.log(this.searchField);
         if (data["success"]) {
           this.datalist = data["result"];
           //console.log(this.datalist);
          /* this.page.first = data["result"].first;
           this.page.last = data["result"].last;
           this.page.number = data["result"].number;
           this.page.numberOfElements = data["result"].numberOfElements;
           this.page.offset = data["result"].offset;
           this.page.size = data["result"].size;
           this.page.totalElements = data["result"].totalElements;
           this.page.totalPages = data["result"].totalPages;
           if (this.page.number == 0) {
             this.gridComp.setPages();
           }
         } else {
           alert("Failed to get rfid list!");
         }*/
       }
       });
   }
   disableSave(f: any) {
    let isDisableSave: boolean = true;
  //  console.log(f);
    if (f.valid) {
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
    }
    return isDisableSave;
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  }

   onRowDblClick(sublistvalue:any) {
    this._action = "update";
    this.isDetailScreen = true;
    //this.isScreen = false;
    //this.router.navigate(['user']);
    this.getDetail(sublistvalue.subId);
  }
   getDetail(Id) {
    if (Id) {
       this.subscriptionservice.getSubscription(Id).subscribe(data => {
         if (data["success"]) {
           this.data = data["result"];
           this.data.gents=new Date(this.data.gents);
           this.data.modts=new Date(this.data.modts);
           this._data = this.tools.copyFullObject(this.data);
           this.breakFormulaValue(this.data.formula);
         }
       }
       )}
   }
   breakFormulaValue(formulaValue:string){
     let value = formulaValue;
     let valueArray = value.split("#");
     let rawCharging = valueArray[0];
     let chargingArray = rawCharging.split("$");
     this.chargingValue.byCUnit=chargingArray[0].replace("U","")
     this.chargingValue.byCMinute=chargingArray[1].replace("M","")
     this.chargingValue.byCHour=chargingArray[2].replace("H","")
     let rawParking = valueArray[1];
     let parkingArray = rawParking.split("$");
     this.parkingValue.byPUnit=parkingArray[0].replace("U","")
     this.parkingValue.byPMinute=parkingArray[1].replace("M","")
     this.parkingValue.byPHour=parkingArray[2].replace("H","")
     let rawOther = valueArray[2];
     let otherArray = rawOther.split("$");
     this.otherValue.byOUnit=otherArray[0].replace("U","")
     this.otherValue.byOMinute=otherArray[1].replace("M","")
     this.otherValue.byOHour=otherArray[2].replace("H","")

     if(this.chargingValue.byCUnit==="-1"){
      this.chargingValue.byCUnit="NA"
    }
    if(this.chargingValue.byCMinute==="-1"){
      this.chargingValue.byCMinute="NA"
    }
    if(this.chargingValue.byCHour==="-1"){
      this.chargingValue.byCHour="NA"
    }
    if(this.parkingValue.byPUnit==="-1"){
      this.parkingValue.byPUnit="NA"
    }
    if(this.parkingValue.byPMinute==="-1"){
      this.parkingValue.byPMinute="NA"
    }
    if(this.parkingValue.byPHour==="-1"){
      this.parkingValue.byPHour="NA"
    }
    if(this.otherValue.byOUnit==="-1"){
      this.otherValue.byOUnit="NA"
    }
    if(this.otherValue.byOMinute==="-1"){
      this.otherValue.byOMinute="NA"
    }
    if(this.otherValue.byOHour==="-1"){
      this.otherValue.byOHour="NA"
    }
    if(this.parkingValue.byPUnit!=="NA"||this.parkingValue.byPMinute!=="NA"||this.parkingValue.byPHour!=="NA"){
      this.isParking = false;
      this.hideP = true;
      this.commercialTypes="O";
    } else{
      this.isParking = true;
    }
    if(this.otherValue.byOUnit!=="NA"||this.otherValue.byOMinute!=="NA"||this.otherValue.byOHour!=="NA"){
      this.isOthers = false;
      this.hideO = true;
      this.commercialTypes="P";
    }
    else{
      this.isOthers = true;
    }

    if(!this.isParking && !this.isOthers ){
      this.iscommercialAllowed = true;

    }
    

  }
  statusValue(value){
    if(value==="A"){
      return "Active"
    }else if(value==="I"){
      return "Inactive"
    }
    else{
      return value
    }

  }
  scopeValue(value){
    if(value==="U"){
      return "User"
    }else if(value==="C"){
      return "Charger"
    }else if(value==="B"){
      return "Business"
    }
    else{
      return value
    }
  }

  onBackClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Details has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";
      this.isDetailScreen = false;
    }
  }
  isModified() {
    if (!(this.data && this._data)) {
      return false;
    } else if (this.data.name != this._data.name) {
      return true;
    } else if (this.data.scope != this._data.scope) {
      return true;
    } else if (this.data.formula != this._data.formula) {
      return true;
    } else if (this.data.gents != this._data.gents) {
      return true;
    } else if (this.data.modts != this._data.modts) {
      return true;
    } 
      return false;
  }


  isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }
  createformulaValue(){
    let value="";
    if(this.chargingValue.byCUnit===""||this.chargingValue.byCUnit==="NA"){
      this.chargingValue.byCUnit="-1"
    }
    if(this.chargingValue.byCMinute===""||this.chargingValue.byCMinute==="NA"){
      this.chargingValue.byCMinute="-1"
    }
    if(this.chargingValue.byCHour===""||this.chargingValue.byCHour==="NA"){
      this.chargingValue.byCHour="-1"
    }
    if(this.parkingValue.byPUnit===""||this.parkingValue.byPUnit==="NA"){
      this.parkingValue.byPUnit="-1"
    }
    if(this.parkingValue.byPMinute===""||this.parkingValue.byPMinute==="NA"){
      this.parkingValue.byPMinute="-1"
    }
    if(this.parkingValue.byPHour===""||this.parkingValue.byPHour==="NA"){
      this.parkingValue.byPHour="-1"
    }
    if(this.otherValue.byOUnit===""||this.otherValue.byOUnit==="NA"){
      this.otherValue.byOUnit="-1"
    }
    if(this.otherValue.byOMinute===""||this.otherValue.byOMinute==="NA"){
      this.otherValue.byOMinute="-1"
    }
    if(this.otherValue.byOHour===""||this.otherValue.byOHour==="NA"){
      this.otherValue.byOHour="-1"
    }
    value = "U"+this.chargingValue.byCUnit+"$M"+this.chargingValue.byCMinute+"$H"+this.chargingValue.byCHour+"#U"+this.parkingValue.byPUnit+"$M"+this.parkingValue.byPMinute+"$H"+this.parkingValue.byPHour+"#U"+this.otherValue.byOUnit+"$M"+this.otherValue.byOMinute+"$H"+this.otherValue.byOHour;
    return value;
  }
  
  saveData() {
    console.log(this.data.scope)
    this.data.formula = this.createformulaValue();
    /*if(!this.data.userName){
        this.data.status = "A";
      }
    else {
        this.data.status = "I";
      }
      if(this.data.balanceAmt===null){
        this.data.balanceAmt=0.00;
      }*/
    this.subscriptionservice.saveSubscription(this.data,this._action).subscribe(data => {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      if (data["success"]) {
        this._isSaved = true;
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Information";
        modalRef.componentInstance.message = "Tariff Plan saved successfully!";
        modalRef.componentInstance.btnCLass = "btn-info";
        modalRef.result.then(
          result => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
            if (result == "ok") {
              if (result == "ok") {
                this.getSubscriptionList();
                this.isDetailScreen = false;
              }
              this.isDetailScreen = false;
            }
          },
          reason => {
            this.data = data["result"];
            this._data = this.tools.copyFullObject(this.data);
          }
        );
      } 
      
      else {
        const modalRef = this.ngbModalService.open(
          NgInformationModalContent,
          ngmodaloptions
        );
        modalRef.componentInstance.title = "Error";
        modalRef.componentInstance.message = "Failed to save Discount Code!";
        modalRef.componentInstance.btnCLass = "btn-warning";
        modalRef.result.then(
          result => {},
          reason => {}
        );
        // alert("station not saved");
      }
    });
  }
  async onAddNew() {
    this.data = new SubscriptionModel();
    this._data = this.tools.copyFullObject(this.data);
    this._action = "new";
    this.data.scope = "U";
    this.data.status = 'A';
    this.commercialTypes="P";
    this.isDetailScreen = true;

  }
}

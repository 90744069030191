export class TransactionModel {
  tranId?: number;
  cp_identity?: string;
  connector_id?: number;
  meter_start?: string;
  meter_end?: string;
  start_time?: Date;
  stop_time?: Date;
  reservationId?: string;
  activitylog?:string;
  amp?:number;
  bookingId?:number;
  soc?:string;
  /*
   * S- Booking accepted
   * R -  Charging Running
   * C - Charging Complete
   */
  status?: string;
  unitConsumed?:number;
}

import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import { AppSettings } from "../config/AppSettings";
import { Tools } from '../modules/tools';
import {GridPage} from '../util/components/gridtable/gridtable.component';

@Injectable({
  providedIn: 'root'
})
export class DiscountService {

  constructor(private http: HttpClient, private appsetting: AppSettings,private tools: Tools) {}

  saveDiscount(body,requestType:string) {
   /* if(requestType==="bulk"){
    let header = new HttpHeaders();
    return this.http.post( this.appsetting.get("server-url") + '/api/rfidbulk',body,
    {headers : header,observe : "body", responseType : "json"})
      .map(res => res);
    }else{*/
    let header = new HttpHeaders();
    return this.http.post( this.appsetting.get("server-url") + '/api/disccode',body,
    {headers : header,observe : "body", responseType : "json"})
      .map(res => res);
    
  }
  getDiscount(code: string) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("code", code.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/code", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }

getDiscountList(searchField) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
  //  httpParam=httpParam.append("page", pageinfo.number.toString());
  //  httpParam=httpParam.append("size",pageinfo.size.toString());
   //httpParam=httpParam.append("status",searchField.status);
  // httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
  //  httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));    
      httpParam = httpParam.append("username", searchField.searchText);
      httpParam = httpParam.append("code", searchField.code);
      httpParam=httpParam.append("status",searchField.status);
      httpParam=httpParam.append("type",searchField.type);
      return this.http.get( this.appsetting.get("server-url") + '/api/codelist',{headers : header, observe : "body", params : httpParam,responseType : "json"})
    .map(res=>res);
  }
 
  
/*deleteRFID(bookingId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("bookingId", bookingId.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/changebookingstatus", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }*/
}

export class TariffRuleModel {
    name?:string;
    ruleID?: number;
    tariffPlan?: string;
    schedule?:string;
    scope?:string;
    cancellationPolicy?:string;
    validFrom?: Date;
    validTill?: Date;
    modts?: Date;
    gents?: Date;

}

import { Component, OnInit, ViewChild, DoCheck } from "@angular/core";
import { TopbarText } from "../topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import {FTransactionsService } from "src/app/services/ftransactions.service";
import { FTransactionsModel } from "../../models/ftransactions.model";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions
} from "@ng-bootstrap/ng-bootstrap";
import {
  NgYesNoModalContent,
  NgInformationModalContent
} from "../../util/components/modalcomponent/modal-content";
class searchField {
  transactionId?: string;
  amount?: number;
  startdate?: Date;
  enddate?: Date;
  status?:string;
  username?:string;
}

export class EveryTransactionModel {
  transactionId?:number;
  amount?:number;
  appuserName?:String;
  bookingId?:String;
  pgLog?:String;
  status?:string;
  type?:string;
  lastUpdateTime?:string;
  pgOrderGenTime?:string;
  pgPaymentId?:string;
  pgSIgnature?:string;
  pgOrderId?:string;
}
@Component({
  selector: 'app-ftransactions',
  templateUrl: './ftransactions.component.html',
  styleUrls: ['./ftransactions.component.css']
})
export class FTransactionsComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Id",
      columnFieldName: "transactionId",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "AppUsername",
      columnFieldName: "appuserName",
      columnClasses: "c-col-pc-10"
    },
    
    {
      columnHeader: "Status",
      columnFieldName: "status",
      columnClasses: "c-col-pc-10",
      callbackFunction: this.tools.transactionStatus
    },
    {
      columnHeader: "Amount",
      columnFieldName: "amount",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Type",
      columnFieldName: "type",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "DateTime",
      columnFieldName: "pgOrderGenTime",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.defaultdateTimeFormat
    }
  ];
  
  constructor(
    public ftransactionService: FTransactionsService,
    public tools: Tools,
    public objGlobal: global,
    public auth: AuthService,
  ) {
    this._data = new FTransactionsModel();
    this.data = new EveryTransactionModel();
    this.searchField = new searchField();
    this.page = new GridPage();
    this._menuId = this.tools.getMenuName("main/transdetails");
    this.objGlobal.setActiveMenu(this._menuId);
    this.searchField.status="I";
    this.searchField.startdate=this.dateNow;
    this.searchField.enddate=this.dateNow;
    this.page.size = 10; //Default page size
    this.getTransactionsData() ;
   }
  datalist: Array<FTransactionsModel>;
  paymentData:Array<FTransactionsModel>;
  isDetailScreen: boolean = false;
  _data:FTransactionsModel;
  _action: string;
  data: EveryTransactionModel;
  page: GridPage;
  _menuId: string;
  dateNow: Date = new Date();
  topbartext: TopbarText[] = [{ key: "trans", displayText: "Payment Details" }];
  searchField: searchField;
  ngOnInit() {
  }

  getTransactionsData() {
    this.ftransactionService.getFTransactionsDetails(this.page, this.searchField).subscribe(data => {
    // if(data["result"].content.booking.status!=="P"){
      if (data["success"]) {  
        console.log(data["result"].content);
        this.datalist = data["result"].content;
        this.page.first = data["result"].first;
        this.page.last = data["result"].last;
        this.page.number = data["result"].number;
        this.page.numberOfElements = data["result"].numberOfElements;
        this.page.offset = data["result"].offset;
        this.page.size = data["result"].size;
        this.page.totalElements = data["result"].totalElements;
        this.page.totalPages = data["result"].totalPages;
        
        this.createPaymentData  (this.datalist);
        if (this.page.number == 0) {
          this.gridComp.setPages();

        }
      } else {
        alert("Failed to get Transaction Details!");
      }
      
//}
     // this.bookings = data["result"].content.booking;
    });
   
    //for(let i=0 ;i<this.datalist.length;i++){
    //  this.bookings=this.datalist.
    //}
  }

  onBackbuttonClick() {
    this.isDetailScreen=false;
          //this.getChargingData();
    }
   
    onRowDblClick(ftransaction: any) {
      this._action = "update";
      this.isDetailScreen = true;
      console.log(ftransaction);
     this.getTransactionDetail(ftransaction.transactionId);
    }

   getTransactionDetail(ftranId:number) {
      console.log(ftranId)
      if (ftranId) {
       this.ftransactionService.getFTransactionDetail(ftranId).subscribe(data => {
        if (data["success"]) {
        this._data = data["result"];
        this.data.amount = this._data.amount;
        this.data.appuserName = this._data.appuserName;
        this.data.bookingId = this._data.bookingId;
        this.data.lastUpdateTime = this.tools.defaultdateTimeFormat(this._data.lastUpdateTime);
        this.data.pgLog = this._data.pgLog;
        this.data.pgOrderGenTime = this.tools.defaultdateTimeFormat(this._data.pgOrderGenTime);
        this.data.pgOrderId = this._data.pgOrderId;
        this.data.pgPaymentId = this._data.pgPaymentId;
        this.data.pgSIgnature = this._data.pgSIgnature;
        this.data.transactionId = this._data.transactionId;
        this.data.type = this._data.type;
        this.data.status = this.tools.transactionStatus(this._data.status);
        
        } else {
          this.data = new EveryTransactionModel();
          alert("Failed to get charging data!");
        }
      });
    }
  }

  export(){
    //console.log(this.paymentData)
    var csvData = this.ConvertToCSV( this.paymentData);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    var x:Date = new Date();
    var link:string ="Payment_"+ this.tools.formatDateToDDMMYY(x) +'.csv';
    a.download = link.toLocaleLowerCase();
    a.click();

  }
  createPaymentData(dataArray){
    console.log(dataArray)
    if(dataArray){
      let paymentlist: Array<FTransactionsModel> = new Array<FTransactionsModel>();
      
      let totalUnit=0;
      let totalAmt=0;
      let totalArray:FTransactionsModel = new FTransactionsModel();
      console.log(dataArray.length)
      for(let i=0;i<dataArray.length;i++){
        const payment: FTransactionsModel = new FTransactionsModel();
        payment.pgPaymentId = dataArray[i].pgPaymentId;
       payment.pgOrderId = dataArray[i].pgOrderId;
        payment.transactionId = dataArray[i].transactionId;
        payment.status = dataArray[i].status;
        payment.type = dataArray[i].type;
        payment.pgSIgnature = dataArray[i].pgSIgnature;
        payment.appuserName = dataArray[i].appuserName;
        payment.lastUpdateTime = dataArray[i].lastUpdateTime;
        payment.pgOrderGenTime= dataArray[i].pgOrderGenTime;
        console.log(payment);
        paymentlist.push(payment);
        totalAmt=totalAmt+dataArray[i].amount;
        totalUnit= totalUnit+dataArray[i].unit;
      }
      console.log(paymentlist)
      this.paymentData = paymentlist;
    }
  }

  ConvertToCSV(objArray) {
    //console.log(objArray)
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
        //Now convert each value to string and comma-separated
        row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
  }

}

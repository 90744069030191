import { StationModel } from "./station.model";

export class ManageChargerModel {
  chargerId: number;
  chargerName?: string;
  chargerType?: string;
  connector?: string;
  category?: string;
  outputType?: String;
  ratedVoltages?: string;
  number_of_chargingpoints?: Number;
  chargerCapacity?: number;
  number_of_gun?: number;
  gunCapacity?: number;
  station?: StationModel;
  price?: number;
  pricePerMinute?: number;
  pricePerHour?: number;
  discount?: number;
  othercharges?:number;
  config?:string;
  protocol?:string;
  icon?:string;
  fileData?:string;
  label?:string;
  //U - price per unit
  //M - price per minute
  priceBy?: string;

  currentStatus?: string;
  ocppStatus?: string;
  ocppVersion?: string;
  operationalHours?:string;
  mode?:string;
  deviceStatus?:string;
  tariffPlan:number;
}

import { Component, OnInit, ViewChild } from "@angular/core";
import {
  GridtableComponent,
  GridPage,
  gridColumns
} from "src/app/util/components/gridtable/gridtable.component";
import { TopbarText } from "../topbar/topbar.component";
import { PaymentModel } from "src/app/models/payment.model";
import { Tools } from "src/app/modules/tools";
import { global } from "src/app/modules/global";
import { AuthService } from "src/app/services/auth.service";
import { StationModel } from "src/app/models/station.model";
import { ChargerService } from "src/app/services/charger.service";
import { StationsService } from "src/app/services/Stations.Service";
import { OperatorModel } from "src/app/models/operator.model";
import { OperatorService } from "src/app/services/operator.service";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { RevenueReportService } from "src/app/services/revenue-report.service";

export class searchField {
  operatorId?: number;
  chargerId?: number;
  stationId?: number;
  paymentId?: number;
  startTime?: Date;
  stopTime?: Date;
}
export class SortField {
  sortFieldName: string;
  sortDirection: string;
}
export class RevenueData {
  PaymentId?: String;
  BookingId?: String;
  StartTime?: String;
  StopTime?: String;
  Station?: String;
  Consumption?: String;
  Duration?:String;
  Amount?:String;
}

@Component({
  selector: "app-revenue-report",
  templateUrl: "./revenue-report.component.html",
  styleUrls: ["./revenue-report.component.css"]
})
export class RevenueReportComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  topbartext: TopbarText[] = [
    { key: "revenuereport", displayText: "Revenue Report" }
  ];
  data: Array<PaymentModel>;
  revenueData:Array<RevenueData>;
  page: GridPage;
  _menuId: string;
  searchField: searchField;
  stationlist: Array<StationModel>;
  station: StationModel;
  operator: OperatorModel;
  operatorlist: Array<OperatorModel>;
  chargerslist: Array<ManageChargerModel>;
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "Payment Id",
      columnFieldName: "paymentId",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Start Time",
      columnFieldName: "booking.start_time",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Stop Time",
      columnFieldName: "booking.stop_time",
      columnClasses: "c-col-pc-15",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "Station",
      columnFieldName: "status",
      columnClasses: "c-col-pc-15",
      //callbackFunction: this.socValue
    },
    {
      columnHeader: "Session/Duration",
      columnFieldName: "timediff",
      columnClasses: "c-col-pc-18",
      callbackFunctionRowPrm: this.calculateDuration
    },
    {
      columnHeader: "Consumption",
      columnFieldName: "unit",
      columnClasses: "c-col-pc-12"
    },
    {
      columnHeader: "Billed Amount",
      columnFieldName: "amount",
      columnClasses: "c-col-pc-12",
      callbackFunction: this.formatAmount
    }
  ];

  sortFields = [
    { value: "paymentId", text: "Invoice Id" },
    { value: "tran.start_time", text: "Start Time" },
    { value: "tran.stop_time", text: "Stop Time" },
    { value: "duration", text: "Duration" },
    { value: "unit", text: "Consumption" },
    { value: "amount", text: "Amount" }
  ];
  sortField: SortField;
  constructor(
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    public chargerservice: ChargerService,
    private stationservice: StationsService,
    private operatorservice: OperatorService,
    private managechargerservice: ManageChargerService,
    private revenuereportservice: RevenueReportService
  ) {
    this.page = new GridPage();
    this.page.size = 20; //Default page size
    this._menuId = this.tools.getMenuName("main/revenuereport");
    this.objGlobal.setActiveMenu(this._menuId);
    this.searchField = new searchField();
    this.sortField = new SortField();
    this.searchField.operatorId= 0;
    this.searchField.stationId= 0;
    this.searchField.chargerId= 0;
    this.searchField.startTime= new Date();
    this.searchField.stopTime= new Date();
    this.sortField.sortFieldName = "paymentId";
    this.sortField.sortDirection = "D";
    this.getStations();
    this.getOperators();
    this.getChargers();
    console.log(this.searchField.operatorId);
  }

  ngOnInit() {}

  socValue(val ) {
    if(val) {
      return val + "%";
    }
  }

  calculateDuration(rowValue: PaymentModel) {
    let diff: number;
    let result: string = "";
    let minusString: string = "";
    if (
      rowValue &&
      rowValue.booking &&
      rowValue.booking.stop_time &&
      rowValue.booking.start_time
    ) {
      diff =
        new Date(rowValue.booking.stop_time).getTime() -
        new Date(rowValue.booking.start_time).getTime();

      if (diff < 0) {
        diff = diff * -1;
        minusString = "-";
      }
      let secDiff = diff / 1000;
      let minuteDiff = Math.floor(secDiff / 60);
      secDiff = secDiff - minuteDiff * 60;
      let hrDiff = Math.floor(minuteDiff / 60);
      minuteDiff = minuteDiff - hrDiff * 60;
      if (hrDiff && hrDiff > 0) {
        result = result + minusString + hrDiff;
        if (hrDiff == 1) {
          result = result + " hr";
        } else {
          result = result + " hrs";
        }
      }
      if (result != "") {
        result = result + " ";
      }
      if (minuteDiff && minuteDiff > 0) {
        result = result + minusString + minuteDiff;
        if (minuteDiff == 1) {
          result = result + " minute";
        } else {
          result = result + " minutes";
        }
      }
      if (result != "") {
        result = result + " ";
      }
      if ((secDiff && secDiff > 0) || result == "") {
        result = result + minusString + secDiff;
        if (secDiff == 1 || secDiff == 0) {
          result = result + " second";
        } else {
          result = result + " seconds";
        }
      }
    }
    return result;
  }

  async getStations() {
    await this.stationservice
      .getAllStationsWithSuccessorOperator()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          let stations: Array<StationModel> = new Array<StationModel>();
          let station: StationModel = new StationModel();
          station.stationId = undefined;
          station.stationname = "All";
          stations.push(station);
          this.stationlist = data["result"];
        } else {
          console.error("Error in fetching alll stations");
        }
      });
  }

  async getOperators() {
    this.operatorlist = undefined;
    await this.operatorservice
      .getAllOperatorsWithSuccessor()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          this.operatorlist = data["result"];
        } else {
          console.error("Error in fetching alll operators");
        }
      });
  }

  async getChargers() {
    this.chargerslist = undefined;
    await this.managechargerservice
      .getChargersSelectionListWithSuccessorOperator()
      .toPromise()
      .then(data => {
        if (data["success"]) {
          this.chargerslist = data["result"];
        } else {
          console.error("Error in fetching all chargers");
        }
      });
  }

  getChargersList() {
    if (this.searchField.stationId && this.searchField.stationId != 0) {
      return this.chargerslist.filter(
        obj => obj.station.stationId == this.searchField.stationId
      );
    } else if (
      this.searchField.operatorId &&
      this.searchField.operatorId != 0
    ) {
      return this.chargerslist.filter(
        obj => obj.station.operator.operatorId == this.searchField.operatorId
      );
    } else {
      return this.chargerslist;
    }
  }

  getStationsList() {
    if (this.searchField.operatorId && this.searchField.operatorId != 0) {
      return this.stationlist.filter(
        obj => obj.operator.operatorId == this.searchField.operatorId
      );
    } else {
      return this.stationlist;
    }
  }

  getList() {
    this.revenuereportservice
      .getRevenueReportList(this.page, this.searchField, this.sortField)
      .subscribe(data => {
        if (data["success"]) {
          this.data = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
          this.createRevenueData(this.data);
        } else {
          alert("Failed to get revenue report!");
        }
      });
  }

  formatAmount(val: number) {
    return val.toFixed(2);
  }
  export(){
    var csvData = this.ConvertToCSV( this.revenueData);
    var a = document.createElement("a");
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url= window.URL.createObjectURL(blob);
    a.href = url;
    var x:Date = new Date();
    var link:string ="Revenue_"+ this.tools.formatDateToDDMMYY(x) +'.csv';
    a.download = link.toLocaleLowerCase();
    a.click();

  }
  createRevenueData(dataArray){
    console.log(dataArray)
    if(dataArray){
      let revenuelist: Array<RevenueData> = new Array<RevenueData>();
      
      let totalUnit=0;
      let totalAmt=0;
      let totalArray:RevenueData = new RevenueData();
      console.log(dataArray.length)
      for(let i=0;i<dataArray.length;i++){
        const revenue: RevenueData = new RevenueData();
        revenue.PaymentId = dataArray[i].paymentId;
        revenue.BookingId = dataArray[i].booking.bookingId;
        revenue.Station = dataArray[i].status;
        revenue.StartTime = dataArray[i].booking.start_time;
        revenue.StopTime = dataArray[i].booking.stop_time;
        revenue.Duration = this.calculateDuration(dataArray[i]);
        revenue.Consumption = this.formatAmount(dataArray[i].unit);
        revenue.Amount = this.formatAmount(dataArray[i].amount);
        console.log(revenue);
        revenuelist.push(revenue);
        totalAmt=totalAmt+dataArray[i].amount;
        totalUnit= totalUnit+dataArray[i].unit;
      }
      totalArray.PaymentId = " ";
      totalArray.BookingId = " ";
      totalArray.Station = " ";
      totalArray.StartTime = " ";
      totalArray.StopTime = " ";
      totalArray.Duration = "Total";
      totalArray.Consumption=this.formatAmount(totalUnit).toString();
      totalArray.Amount=this.formatAmount(totalAmt).toString();
      console.log(revenuelist);
      revenuelist.push(totalArray);
      this.revenueData = revenuelist;
    }
  }

  ConvertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = "";

    for (var index in objArray[0]) {
        //Now convert each value to string and comma-separated
        row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
        var line = '';
        for (var index in array[i]) {
            if (line != '') line += ','

            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return str;
  }

}

/*
import { MatSort, MatTableDataSource } from "@angular/material";
import { TopbarText } from "../topbar/topbar.component";
import { OCPPLiveStatus } from "src/app/models/ocpp.list.status";
import { DatePipe } from "@angular/common";
import { OCPPStatusMasterService } from "src/app/services/ocpp.status.master.service";
import { OCPPStatusMasterModel } from "src/app/models/ocpp.status.master.model";
import { interval } from "rxjs";
import { ChargerActionQueueService } from "src/app/services/charger.action.queue.service";
import { Tools } from "src/app/modules/tools";
import { SimpleMessage } from "src/app/modules/util.class";
import { ChargerActionQueueModel } from "src/app/models/charger.action.queue.model";
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgYesNoModalContent } from "src/app/util/components/modalcomponent/modal-content";
declare var jquery: any;
declare var $: any;*/

import { AuthService } from "src/app/services/auth.service";
import { BookingStatusModel } from "../../models/booking.status.model";
import { BookingModel } from "../../models/booking.model";
import { ChargerService } from "src/app/services/charger.service";
import { ManageChargerService } from "src/app/services/ManageCharger.service";
import { ManageChargerModel } from "src/app/models/ManageCharger.model";
import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import {
  GridPage,
  gridColumns,
  GridtableComponent
} from "../../util/components/gridtable/gridtable.component";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { NgbModalOptions, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  NgInformationModalContent,
  NgYesNoModalContent
} from "src/app/util/components/modalcomponent/modal-content";
import { ChargerActionService } from "src/app/services/charger-action.service";
import { Tools } from "../../modules/tools";
import { Router,ActivatedRoute } from "@angular/router";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { TransactionModel } from "src/app/models/transaction.model";

export class searchField {
  searchField?: number;
  chargerId?: string;
  tranId?: number;
  startdate?: Date;
  enddate?: Date;
  status?:string;
  username?:string;
}
export class ProgressDataModel {
  id?: number;
  deviceId?: string;
  archive?:number;
  connectorId?: number;
  statusts?: Date;
  status?:string;
  statusext?:string;
  tranid?:string;
}

export class ProgressValueModel {
  value?: string; 
  date?:string;
  status?:string;
}
export class stopTransaction {
  fromStatus : string;
  chargingpoint : string;
  deviceId : string;
  requestStatus :string;
  bookingId : number;
}
export class EveryChargingModel {
  tranId?: number;
  cp_identity?: string;
  connector_id?: number;
  meter_start?: string;
  meter_end?: string;
  start_time?: string;
  stop_time?: string;
  reservationId?: string;
  activitylog?:string;
  amp?:number;
  bookingId?:number;
  soc?:string;
  
  /*
   * S- Booking accepted
   * R -  Charging Running
   * C - Charging Complete
   */
 
  status?: string;
  unitConsumed?:number;
}

@Component({
  selector: "app-chargeraction",
  templateUrl: "./chargeraction.component.html",
  styleUrls: ["./chargeraction.component.css"],
})
export class ChargeractionComponent implements OnInit, OnDestroy {
  topbartext: TopbarText[] = [
    { key: "chargingstatus", displayText: "Active Charging Sessions" }
  ];
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", 
    isSrCol: true, 
    columnClasses: "c-col-pc-2" },
    {
      columnHeader: "ChargingId",
      columnFieldName: "tranId",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Charger",
      columnFieldName: "cp_identity",
      columnClasses: "c-col-pc-18"
    },
    {
      columnHeader: "Station",
      columnFieldName: "station",
      columnClasses: "c-col-pc-15"
    },
    {
      columnHeader: "Port",
      columnFieldName: "connector_id",
      columnClasses: "c-col-pc-5"
    },
    {
      columnHeader: "SOC",
      columnFieldName: "soc",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Status",
      columnFieldName: "status",
      columnClasses: "c-col-pc-6",
      callbackFunction: this.tools.chargingStatus
    },
    {
      columnHeader: "StartTime",
      columnFieldName: "start_time",
      columnClasses: "c-col-pc-17",
     callbackFunction: this.tools.defaultdateTimeFormat
    },
    {
      columnHeader: "StopTime",
      columnFieldName: "stop_time",
      columnClasses: "c-col-pc-17",
     callbackFunction: this.tools.defaultdateTimeFormat
    },
    
  ];
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  bookings: Array<BookingModel>;
  datalist: Array<BookingStatusModel>;
  chargerslist: Array<ManageChargerModel>;
  _menuId: string;
  page: GridPage;
  _data:TransactionModel;
  data: EveryChargingModel;
  progressData: Array<ProgressDataModel>;
  progressValues: Array<ProgressValueModel>;
  tranId:number;
  _action: string;
  isInitiate:boolean = false;
  isDetailScreen: boolean = false;
  qrModal : boolean;
  dateNow: Date = new Date();
  searchField: searchField;
  stopTransaction: stopTransaction;
  formGroup: FormGroup;
  constructor(
    private chargerActionService: ChargerActionService,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private activatedRoute:ActivatedRoute,
    public chargerservice: ChargerService,
    private managechargerservice: ManageChargerService, 
    private ngbModalService: NgbModal,
    private _formBuilder: FormBuilder
  )
   {
     this.stopTransaction = new stopTransaction();
    this.searchField = new searchField();
    this._data = new TransactionModel();
    this.data = new EveryChargingModel();
    this.page = new GridPage();
    this.page.size = 10; //Default page size
    this._menuId = this.tools.getMenuName("main/chargeraction");
    this.objGlobal.setActiveMenu(this._menuId);
    if(this.activatedRoute.snapshot.paramMap.get('chargerId')!=null){
      this.searchField.chargerId=this.activatedRoute.snapshot.paramMap.get('chargerId');
     }else{
     this.searchField.chargerId="All";
     }

     this.searchField.status="R";
     this.searchField.startdate=this.dateNow;
     this.searchField.enddate=this.dateNow;
     this.stopTransaction.fromStatus="Available";
     this.stopTransaction.requestStatus="StopTransaction";
     this.getChargingData();
     this.getChargers();
    }
    ngOnInit() {
      this.formGroup = this._formBuilder.group({
        firstCtrl: ['', Validators.required],
      });
      //this.dataSource.data = this.getGroups(this.alldata, this.groupByColumns);
      //this.getChargersLiveStatusData();
    }
    ngOnDestroy() {
      //this.subscriptionStatusRefresh.unsubscribe();
    }
    gridDeleteButton() {
      // console.log("abc");
       let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
       //console.log(isdelete)
      // let isdelete : boolean = true;
       return isdelete;
     }
      async getChargers() {
       this.chargerslist = undefined;
       await this.managechargerservice
         .getChargersSelectionListWithSuccessorOperator()
         .toPromise()
         .then(data => {
           if (data["success"]) {
             this.chargerslist = data["result"];
           } else {
             console.error("Error in fetching all chargers");
           }
         });
     }
   
     getChargersList() {
       return this.chargerslist;
       
     }

     getChargingData() {
      this.chargerActionService.getChargingDetails(this.page, this.searchField).subscribe(data => {
      // if(data["result"].content.booking.status!=="P"){
        if (data["success"]) {  
          console.log(data["result"].content);
          this.datalist = data["result"].content;
          this.page.first = data["result"].first;
          this.page.last = data["result"].last;
          this.page.number = data["result"].number;
          this.page.numberOfElements = data["result"].numberOfElements;
          this.page.offset = data["result"].offset;
          this.page.size = data["result"].size;
          this.page.totalElements = data["result"].totalElements;
          this.page.totalPages = data["result"].totalPages;
          if (this.page.number == 0) {
            this.gridComp.setPages();
          }
        } else {
          alert("Failed to get Charger Details!");
        }
      });
    }
  
  closeCharging(recObj) {
    let objCharging: TransactionModel = {};
    if(!this.isDetailScreen){
      objCharging = recObj.obj;
    }
    else{
      objCharging = recObj;
    }
    if (objCharging && objCharging.status=="R") {
      this.stopTransaction.bookingId=objCharging.bookingId;
      this.stopTransaction.chargingpoint=objCharging.connector_id.toString();
      this.stopTransaction.deviceId = objCharging.cp_identity;
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to stop Charging?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.chargerActionService
              .closeCharging(this.stopTransaction)
              .subscribe(data => {
                if (data["success"]) {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Charging Stop request submited successfully!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      if(this.isDetailScreen){
                        this.getTransactionDetail(this._data.tranId)
                      }else{
                        this.getChargingData();
                      }
                      
                    },
                    reason => {
                      if(this.isDetailScreen){
                        this.getTransactionDetail(this._data.tranId)
                      }else{
                        this.getChargingData();
                      }
                    }
                  );
                }
                 else {
                  alert("Failed to stop charging!");
               
                }
              });
          }
        },);
    } else if (objCharging && objCharging.status=="I") {
      this.tranId=objCharging.tranId;
      this.stopTransaction.chargingpoint=objCharging.connector_id.toString();
      this.stopTransaction.deviceId = objCharging.cp_identity;
      //const objBooking: BookingModel = recObj.obj;
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message = "Are you sure to stop Charging?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this.chargerActionService
              .changeChargingStatus(this.tranId)
              .subscribe(data => {
                if (data["success"]) {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Charging stop request submited successfully!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      if(this.isDetailScreen){
                        this.getTransactionDetail(this._data.tranId)
                      }else{
                        this.getChargingData();
                      }
                    },
                    reason => {
                      if(this.isDetailScreen){
                        this.getTransactionDetail(this._data.tranId)
                      }else{
                        this.getChargingData();
                      }
                    }
                  );
                }
                 else {
                  const modalRefOk = this.ngbModalService.open(
                    NgInformationModalContent,
                    ngmodaloptions
                  );
                  modalRefOk.componentInstance.title = "Information";
                  modalRefOk.componentInstance.message =
                    "Failed to cancel charging!";
                  modalRefOk.componentInstance.btnCLass = "btn-info";
                  modalRefOk.result.then(
                    result => {
                      if(this.isDetailScreen){
                        this.getTransactionDetail(this._data.tranId)
                      }else{
                        this.getChargingData();
                      }
                    },
                    reason => {
                      if(this.isDetailScreen){
                        this.getTransactionDetail(this._data.tranId)
                      }else{
                        this.getChargingData();
                      }
                    });
               
                }
              });
          }
        },);
    }

    else {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      
      const modalRefOk = this.ngbModalService.open(
        NgInformationModalContent,
        ngmodaloptions
      );
      modalRefOk.componentInstance.title = "Information";
      modalRefOk.componentInstance.message =
        "Charging cannot be stopped in current status";
      modalRefOk.componentInstance.btnCLass = "btn-info";
      modalRefOk.result.then(
        result => {
          this.getChargingData();
        },
        reason => {
          this.getChargingData();
        });
   
    }
  }
  onBackbuttonClick() {
    this.isDetailScreen=false;
          this.getChargingData();
    }
    onCancelButtonClick() {
      this.closeCharging(this._data);
      this.getTransactionDetail(this._data.tranId)
      //this.isDetailScreen = false;
}
    onRowDblClick(transaction: any) {
      this._action = "update";
      this.isDetailScreen = true;
     console.log(transaction);
      //this.router.navigate(['user']);
      this.getTransactionDetail(transaction.tranId);
      this.getProgressData(transaction.tranId);
    }
  
    isStateInitiate(){
      if(this._data.status==="I"||this._data.status==="R"){
        return true;
      }else{
        return false
      }

    }

    getTransactionDetail(tranId:number) {
      console.log(tranId)
      if (tranId) {
       this.chargerActionService.getChargingDetail(tranId).subscribe(data => {
        if (data["success"]) {
        this._data = data["result"];
        this.data.amp = this._data.amp;
        this.data.activitylog = this._data.activitylog;
        this.data.bookingId = this._data.bookingId;
        this.data.connector_id = this._data.connector_id;
        this.data.cp_identity = this._data.cp_identity;
        this.data.meter_end = this._data.meter_end;
        this.data.meter_start = this._data.meter_start;
        this.data.reservationId = this._data.reservationId;
        this.data.soc = this._data.soc;
        this.data.tranId = this._data.tranId;
        this.data.unitConsumed = this._data.unitConsumed;
        this.data.status = this.tools.chargingStatus(this._data.status);
        this.data.start_time = this.tools.defaultdateTimeFormat(this._data.start_time);
        this.data.stop_time = this.tools.defaultdateTimeFormat(this._data.stop_time);
        
        } else {
          this.data = new EveryChargingModel();
          alert("Failed to get charging data!");
        }
      });
    }
  }
  getProgressData(tranId:number){
    this.progressValues=undefined;
    if (tranId) {
     
      this.chargerActionService.getProgressDetail(tranId).subscribe(data => {
       if (data["success"]) {
        const progressDataa: Array<ProgressDataModel> = data["results"];
        let progessdataArray : Array<ProgressValueModel>=new Array<ProgressValueModel>();
      
       for (let i: number = 0;i < progressDataa.length; i++) {
        let progressValue:ProgressValueModel = new ProgressValueModel();
          progressValue.value = this.tools.chargingStatus(progressDataa[i].status)+" "+this.tools.defaultdateTimeFormat(progressDataa[i].statusts);
          progressValue.date = this.tools.defaultdateTimeFormat(progressDataa[i].statusts);
          progressValue.status = this.tools.chargingStatus(progressDataa[i].status);
          console.log(progressValue);
          progessdataArray.push(progressValue);
          console.log(progessdataArray);
        }  
        console.log(progessdataArray);     
        this.progressValues = progessdataArray;
       }
      
      });
    }
  }

  onProgressClick(){
    let ngmodaloptions: NgbModalOptions = {};
    ngmodaloptions.backdrop = "static";
    ngmodaloptions.size = "lg";
    
    const modalRefOk = this.ngbModalService.open(
      NgInformationModalContent,
      ngmodaloptions
    );
    modalRefOk.componentInstance.title = "Progress";
    
    modalRefOk.componentInstance.btnCLass = "btn-info";
    modalRefOk.result.then(
      result => {
        this.getChargingData();
      },
      reason => {
        this.getChargingData();
      });
 
  }
  showQRCodes(){
    this.qrModal=true;
    this.generateQR();
  }

  hideQRCodes(){
    this.qrModal=false;
  }
generateQR(){
 // let qrSRC:string;
}

}

import { Component, OnInit, ViewChild, Output, Input,EventEmitter, DoCheck } from "@angular/core";
import { TopbarText } from "../../components/topbar/topbar.component";
import { global } from "../../modules/global";
import { AuthService } from "../../services/auth.service";
import { Tools } from "../../modules/tools";
import { __values } from 'tslib';
//import { RfidDetailComponent } from "../rfid-detail/community.component";
import { OperatorModel } from "src/app/models/operator.model";
import { HttpResponse, HttpEventType } from '@angular/common/http';
import {NgbModal, NgbActiveModal,NgbModalOptions} from "@ng-bootstrap/ng-bootstrap";
import {NgYesNoModalContent, NgInformationModalContent} from "../../util/components/modalcomponent/modal-content";
import { OperatorService } from "src/app/services/operator.service";
import { SubscriptionService } from "../../services/subscription.service"
import {GridPage,gridColumns,GridtableComponent} from "../../util/components/gridtable/gridtable.component";
import { CommunityModel } from "src/app/models/community.model";
import { ColdObservable } from "rxjs/internal/testing/ColdObservable";
import { TariffRuleModel } from "src/app/models/tariffrule.model";
import { CommunityService } from "../../services/Community.service";

@Component({
  selector: 'app-subscription',
  templateUrl: './community.component.html',
  styleUrls: ['./community.component.css']
})
export class CommunityComponent implements OnInit {
  @ViewChild(GridtableComponent) gridComp: GridtableComponent;
  
  gridcols: gridColumns[] = [
    { columnHeader: "Sr", isSrCol: true, columnClasses: "c-col-pc-3" },
    {
      columnHeader: "CorporateId",
      columnFieldName: "communityId",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "Corporate Name",
      columnFieldName: "communityName",
      columnClasses: "c-col-pc-10"
    },
    {
      columnHeader: "EMAIL",
      columnFieldName: "email",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "WEBSITE",
      columnFieldName: "website",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "MOBILE",
      columnFieldName: "mobile",
      columnClasses: "c-col-pc-10",
    },
    {
      columnHeader: "Status",
      columnFieldName: "status",
      columnClasses: "c-col-pc-10",
      
    },
    {
      columnHeader: "GSTIN",
      columnFieldName: "gstin",
      columnClasses: "c-col-pc-18",
    },
    {
      columnHeader: "Creation Date",
      columnFieldName: "generationTime",
      columnClasses: "c-col-pc-18",
      callbackFunction: this.tools.defaultdateTimeFormat
    },
  ];
  searchField = {
    searchStatus:"",
    searchText: "",
    searchSubscription:"",
    scope:"U",
    status:""
  };
  dateNow: Date = new Date();
  tariffrulelist: Array<TariffRuleModel>;
  tarrifrule:TariffRuleModel;
  data: CommunityModel;
    _data: CommunityModel;
    corporateActive:string;
    bankActive:string;
    financialActive:string;
    progress: { percentage: number } = { percentage: 0 };
    _isSaved: boolean = false;
    datalist: Array<CommunityModel>;
    operator: OperatorModel;
    page: GridPage;
    _action: string;
    iscommercialAllowed = false;
    isDetailScreen: boolean = false;
    detailScreenTab = {
      companyprofiletab: true,
      banktab: false,
      financialstab: false,
     
    };
    detailScreenTabHide= {
      companyprofiletab: false,
      banktab: true,
      financialstab: true,
     
    };
    //_disableSaveCancel: boolean = true;
   // isScreen: boolean = true;
    commercialTypes:string;
    _menuId: string;
    topbartext: TopbarText[] = [{ key: "corporates", displayText: "Corporates" }];
   
  constructor(private ngbModalService: NgbModal,
    public tools: Tools,
    private objGlobal: global,
    private auth: AuthService,
    private communityService:CommunityService,
    private subscriptionservice: SubscriptionService) {
      this.data = new CommunityModel();
      this.tarrifrule = new TariffRuleModel();
      this.corporateActive = "";
      this.bankActive="";
      this.financialActive="";
      //this.rfidarrlist = new RFIDModel();
      this.page = new GridPage();
      this.page.size = 10; //Default page size
      this._menuId = this.tools.getMenuName("main/managecommunity");
     // this.searchField.searchText="";
     this.getTariffs();
     this.getCorporateList();
     }

  
   isAllowAdd() {
    let isAdd = this.auth.isPrivExists(this._menuId + "=>add");
    return isAdd;
  }

  onRowDblClick(listval: any) {
    this._action = "update";
    this.isDetailScreen = true;
    this.detailScreenTabHide={companyprofiletab: false,
      banktab: true,
      financialstab: true}
      this.corporateActive="tab-active";
      this.getComunityDetail(listval.communityId);
  
  }
  ngOnInit() {
  }

  dataTarrifPlan() {
    if (!this.data.tariffPlan) {
      return undefined;
    } else {
      return this.data.tariffPlan;
    }
   
  }
  activeInactive(event){
    console.log(event.target.value);
    this.searchField.searchStatus=event.target.value;
    this.getCorporateList();
  }
  isAllowAddorEdit() {
    let isAllowSave = false;
    if (this._action == "new") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>add");
    } else if (this._action == "update") {
      isAllowSave = this.auth.isPrivExists(this._menuId + "=>edit");
    }
    return isAllowSave;
  };
  
  disableSave(f: any) {
    let isDisableSave: boolean = true;
  //  if(f.valid){
     // console.log(this.isModified());
      if (this.isModified()) {
        if (this.isAllowAddorEdit()) {
          isDisableSave = false;
        }
      }
   // }
    return isDisableSave;
  }
  isModified() {
     if (!(this.data && this._data)) {
       return false;
     } else if (this.data.communityName != this._data.communityName) {
       return true;
     } else if (this.data.gstin != this._data.gstin) {
       return true;
     } else if (this.data.address != this._data.address) {
       return true;
     } else if (this.data.email != this._data.email) {
       return true;
     }else if (this.data.status != this._data.status) {
       return true;
     }else if (this.data.mobile != this._data.mobile) {
       return true;
     }else if (this.data.bankName != this._data.bankName) {
       return true;
     }
     else if (this.data.accountNumber != this._data.accountNumber) {
       return true;
     }else if (this.data.category != this._data.category) {
      return true;
    }else if (this.tarrifrule.ruleID != this.dataTarrifPlan()) {
       return true;
     }
     //else if 
    /// (this.subscription.planId !=this.dataSubscriptionId() ) {
     
    //   return true;
   //  }
     return false;
   }
 gridDeleteButton() {
      let isdelete = this.auth.isPrivExists(this._menuId + "=>delete");
      return isdelete;
    }

    saveData() {
    
      console.log (this.tarrifrule);
      if (this.tarrifrule) {
        this.data.tariffPlan= this.tarrifrule.ruleID;
      }else{
        this.data.tariffPlan=null;
      }
      this.communityService.save(this.data,this._action).subscribe(data => {
        let ngmodaloptions: NgbModalOptions = {};
        ngmodaloptions.backdrop = "static";
        ngmodaloptions.size = "sm";
        if (data["success"]) {
          this._isSaved = true;
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Information";
          modalRef.componentInstance.message = "Community saved successfully!";
          modalRef.componentInstance.btnCLass = "btn-info";
          modalRef.result.then(
            result => {
              this.data = data["result"];
              
              this._data = this.tools.copyFullObject(this.data);
              if (result == "ok") {
                //this.getList();
                this.isDetailScreen = false;
              }
            },
            reason => {
              this.data = data["result"];
              this._data = this.tools.copyFullObject(this.data);
            }
          );
        } else {
          const modalRef = this.ngbModalService.open(
            NgInformationModalContent,
            ngmodaloptions
          );
          modalRef.componentInstance.title = "Error";
          modalRef.componentInstance.message = "Failed to save Community!";
          modalRef.componentInstance.btnCLass = "btn-warning";
          modalRef.result.then(
            result => {},
            reason => {}
          );
          // alert("station not saved");
        }
      });
    }

    ontabClick(tabname:string){
     if(tabname==="corporateprofile"){
       console.log(tabname)
      this.detailScreenTabHide={companyprofiletab: false,
        banktab: true,
        financialstab: true}
        this.corporateActive="tab-active"
        this.bankActive=""
        this.financialActive=""
     } else if(tabname==="corporatebank"){
      console.log(tabname)
      this.detailScreenTabHide={companyprofiletab: true,
        banktab: false,
        financialstab: true}
        this.bankActive="tab-active"
        this.corporateActive=""
        this.financialActive=""
    } else if(tabname==="corporatefinancial"){
      console.log(tabname)
      this.detailScreenTabHide={companyprofiletab: true,
        banktab: true,
        financialstab: false}
        this.financialActive="tab-active"
        this.corporateActive=""
        this.bankActive=""
    }
   }

   onBackbuttonClick() {
    if (this.isModified() == true) {
      let ngmodaloptions: NgbModalOptions = {};
      ngmodaloptions.backdrop = "static";
      ngmodaloptions.size = "sm";
      const ngmodalref = this.ngbModalService.open(
        NgYesNoModalContent,
        ngmodaloptions
      );
      ngmodalref.componentInstance.title = "Confirmation";
      ngmodalref.componentInstance.message =
        "Corporate detail has been modified. Are you sure to exit without save?";
      ngmodalref.result.then(
        result => {
          if (result == "yes") {
            this._action = "";
            this.isDetailScreen = false;

            if (this._isSaved) {
              this.getCorporateList();
            }
            this._isSaved = false;
          }
        },
        reason => {}
      );
    } else {
      this._action = "";

      this.isDetailScreen = false;
      if (this._isSaved) {
        this.getCorporateList();
      }
      this._isSaved = false;
    }
  }
    onCloseDetailScreen(isChange: boolean) {
      if (isChange == true) {
       // this.getDatalist();
      }
      this.isDetailScreen = false;
    }

    async onAddNew() {
      this._action = "new";
      this.detailScreenTabHide={companyprofiletab: false,
        banktab: true,
        financialstab: true}
        this.corporateActive="tab-active"
      this.data = new CommunityModel;
      this.data.status="A";
      this._data = this.tools.copyFullObject(this.data);
      this.isDetailScreen = true;
    }
    getCorporateList(){
      console.log(this.searchField.searchStatus)
         this.communityService
           .getCommunityList(this.page, this.searchField)
           .subscribe(data => {
             if (data["success"]) {
               this.datalist = data["result"].content;
               this.page.first = data["result"].first;
               this.page.last = data["result"].last;
               this.page.number = data["result"].number;
               this.page.numberOfElements = data["result"].numberOfElements;
               this.page.offset = data["result"].offset;
               this.page.size = data["result"].size;
               this.page.totalElements = data["result"].totalElements;
               this.page.totalPages = data["result"].totalPages;
               if (this.page.number == 0) {
                 this.gridComp.setPages();
               }
             } else {
               alert("Failed to get CommunityList !");
             }
           });
       }
   
    async getTariffs() {

      this.tariffrulelist = undefined;
      await this.communityService
        .getAllTariffs("B")
        .toPromise()
        .then(data => {
          if (data["success"]) {
            let tariffrules: Array<TariffRuleModel> = new Array<TariffRuleModel>();
            let tariffrule: TariffRuleModel = new TariffRuleModel();
            tariffrule.ruleID = undefined;
            tariffrule.name = "-";
            tariffrules.push(tariffrule);
            const datatariffrule: Array<TariffRuleModel> = data["result"];
            console.log(datatariffrule);
            for (let i: number = 0; i < datatariffrule.length; i++) {
              tariffrules.push(datatariffrule[i]);
            }
            this.tariffrulelist = tariffrules;
            console.log(this.tariffrulelist );
            this._data = this.tools.copyFullObject(this.data);
          } else {
            console.error("Error in fetching alll tariffs");
          }
        });
    }
  
    getComunityDetail(communityId:string) {
   //   console.log(userid);
        this.communityService.getDetail(communityId).subscribe(data =>{
        if(data["success"]) {
            const community= data["result"];
           if(community.tariffPlan){
            const subsItemArr = this.tariffrulelist.filter(itm => itm.ruleID==community.tariffPlan);
           this.tarrifrule = subsItemArr[0];
            console.log(subsItemArr)
          }
           this.data = community;
        } else {
        alert("Failed to get community Data!")
        }    
    })

}
}

import { Component, OnInit,HostListener} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import { Router } from '@angular/router';
import {ImageconfigService} from '../../services/imageconfig.service';
import {global} from '../../modules/global';
import {MenuService} from '../../services/menu.service';
import { AppSettings } from '../../config/AppSettings';
import {Tools} from '../../modules/tools';
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  constructor(private auth:AuthService, private router: Router,
    public imagePath:ImageconfigService, private objGlobal : global,
    public menuservice : MenuService, private appsetting : AppSettings,
    private tools: Tools) {
      this.setDefaultMenuActive('dashboard');
      //console.log("main constructor called");
      if(!this.auth.privileges) {
        auth.loadPrivileges().then(()=> {
          if(!this.menuservice.menusList) {        
            menuservice.loadMenus().then(()=>{
  //            console.log(this.menuservice.menusList);
              
            })
          } 
        })
      } 
     }

  ngOnInit() {
  }
  isActiveMainMenu(id : string) {
    return id==this.objGlobal.activeMainMenu;
  }
  isActiveMenu(id : string) {
    //console.log("active menu is " + this.objGlobal.activeMenu);
    return id==this.objGlobal.activeMenu;
  }
  isChildExists(menu : any) {
    return menu.child && menu.child.length > 0;
  }
  setActiveMainMenu(menu: any) {
    this.objGlobal.activeMainMenu=menu.name;
    if(!this.isChildExists(menu)) {
      this.setActiveMenu(menu);
    }
  }
  setActiveMenu(menu: any) {
    if(!this.isChildExists(menu)) {
      this.objGlobal.activeMenu=menu.name;
    }
  }
  onDashboardClick() {
    this.setDefaultMenuActive('dashboard');
    this.objGlobal.currentMenuPath="dashboard";
    this.router.navigate(['main/dashboard']);    
  }
  onLogHistoryClick() {
    this.setDefaultMenuActive('log_history');
    this.objGlobal.currentMenuPath="log_history";
    this.router.navigate(['main/logs']);    
  }
  setDefaultMenuActive(id) {
    this.objGlobal.activeMainMenu=id;
    this.objGlobal.activeMenu=id;
  }
  onMainMenuClick(menu : any) {
    this.setActiveMainMenu(menu);
  }
  onMenuClick(mainmenu: any, menu : any) {
    //console.log(menu.name);
    this.setActiveMenu(menu);
    this.objGlobal.currentMenuPath=mainmenu.name + "=>" + menu.name;
    const page = this.tools.getMenuNamePage(this.objGlobal.currentMenuPath);
    this.router.navigate([page]);
    /*if(mainmenu.name=="admin") {
      if(menu.name=="admin-role") {
        this.router.navigate(['main/role']);
      }
      else if(menu.name=="admin-user") {
        this.router.navigate(['main/user']);
      }
    }*/
  }
}

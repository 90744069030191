import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppSettings } from "src/app/config/AppSettings";
import { GridPage } from "../util/components/gridtable/gridtable.component";
import { ManageChargerModel } from "../models/ManageCharger.model";

@Injectable()
export class ManageChargerService {
  constructor(private http: HttpClient, private appsetting: AppSettings) {}

  saveCharger(userrole) {
    let header = new HttpHeaders();
    return this.http
      .post(
        this.appsetting.get("server-url") + "/api/managecharger",
        userrole,
        { headers: header }
      )
      .map(res => res);
  }
  saveConfig(chargerName,changeVal,currentValue,) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("Charger", chargerName);
    httpParam = httpParam.append("parameter", changeVal);
    httpParam = httpParam.append("value", currentValue);
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/changeConfig",
        
        { headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json" }
      )
      .map(res => res);
  }
  saveCommand(chargerName,chargerport,command) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("Charger", chargerName);
    httpParam = httpParam.append("connectorId", chargerport);
    httpParam = httpParam.append("Command", command);
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/executeCommand",
        
        { headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json" }
      )
      .map(res => res);
  }
  exeCommand(chargerName,chargerport,command) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("Charger", chargerName);
    httpParam = httpParam.append("connectorId", chargerport);
    httpParam = httpParam.append("Command", command);
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/app/executeCommandCn",
        
        { headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json" }
      )
      .map(res => res);
  }
  resetCommand(chargerName,command) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("Charger", chargerName);
    httpParam = httpParam.append("Command", command);
    return this.http
      .get(
        this.appsetting.get("server-url") + "/api/resetcharger",
        
        { headers: header,
          observe: "body",
          params: httpParam,
          responseType: "json" }
      )
      .map(res => res);
  }
  getChargerList(pageinfo: GridPage, searchField) {
    let header = new HttpHeaders();
    let httpParam: HttpParams = new HttpParams();
    httpParam = httpParam.append("page", pageinfo.number.toString());
    httpParam = httpParam.append("size", pageinfo.size.toString());
    httpParam = httpParam.append("searchText", searchField.searchText);
    httpParam = httpParam.append("searchStatus", searchField.searchStatus);
    return this.http
      .get(this.appsetting.get("server-url") + "/api/managechargerlist", {
        headers: header,
        observe: "body",
        params: httpParam,
        responseType: "json"
      })
      .map(res => res);
  }
  getChargerDetail(chargerId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("chargerId", chargerId.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/managecharger", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  getChargerDetailCn(charger: String) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("charger", charger.toString());
    return this.http
      .get(this.appsetting.get("server-url") + "/api/app/managechargerCn", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }
  async getUnmappedStationChargers(): Promise<Array<ManageChargerModel>> {
    let chargers: Array<ManageChargerModel>;
    await this.getUnmappedStationChargersApi().then(data => {
      if (data["success"]) {
        chargers = data["result"];
      } else {
        console.error("Error in api to get unmapped station chargers");
      }
    });
    return chargers;
  }

  async getUnmappedStationChargersApi() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/unmappedstationcharger", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res)
      .toPromise();
  }

  deleteCharger(chargerId: number) {
    let header = new HttpHeaders();
    let param: HttpParams = new HttpParams();
    param = param.append("chargerId", chargerId.toString());
    return this.http
      .delete(this.appsetting.get("server-url") + "/api/deletecharger", {
        headers: header,
        observe: "body",
        params: param,
        responseType: "json"
      })
      .map(res => res);
  }

  getChargersSelectionList() {
    let header = new HttpHeaders();
    return this.http
      .get(this.appsetting.get("server-url") + "/api/chargersselectlist", {
        headers: header,
        observe: "body",
        responseType: "json"
      })
      .map(res => res);
  }

  getChargersSelectionListWithSuccessorOperator() {
    let header = new HttpHeaders();
    return this.http
      .get(
        this.appsetting.get("server-url") +
          "/api/chargersselectlistwithsuccessoroperator",
        {
          headers: header,
          observe: "body",
          responseType: "json"
        }
      )
      .map(res => res);
  }
  getAllTariffs(scope:String) {
    let header = new HttpHeaders();
    let httpParam : HttpParams=new HttpParams();
   httpParam=httpParam.append("scope", scope.toString());
  //  httpParam=httpParam.append("size",pageinfo.size.toString());
   //httpParam=httpParam.append("status",searchField.status);
  // httpParam=httpParam.append("startdate", this.tools.formatDateToDDMMMYY(searchField.startdate));
  //  httpParam=httpParam.append("enddate",this.tools.formatDateToDDMMMYY(searchField.enddate));    
    //  httpParam = httpParam.append("username", searchField.searchText);
      return this.http.get( this.appsetting.get("server-url") + '/api/alltariffs',{headers : header, observe : "body", params : httpParam,responseType : "json"})
    .map(res=>res);
  }
}

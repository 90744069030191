export class global {
    activeMainMenu : string;
    activeMenu : string;
    currentMenuPath : string;
    Auth : {
        token : any;
        user :  string;
    }
    constructor() {
        this.Auth={token : "", user :""};
       // this.loadAuthToken();
    }
    setActiveMenu(menuname : string) {
        let menusdata=menuname.split("=>");
        this.activeMainMenu=menusdata[0];
        //console.log("set active menu " + menusdata);
        if(menusdata[1]) {
            this.activeMenu=menusdata[1];
        } else {
            this.activeMenu=menusdata[0];
        }
    }
}